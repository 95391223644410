@import 'fonts';

*, body {
    font-family: 'Work Sans', 'Helvetica Neue', sans-serif !important;
}
//Colors
.lightblue {
    background-color: #edf0ff;
    border-bottom: 1px solid #dfeef0;
}
.darkblue {
    background-color: rgb(11, 15, 19);    
}


.navbar {
    &.fixed-top {
        box-shadow: 0 0px 4px 0 #c4c4c4;
    }
    &.bg-light {
        background-color: #fff !important;
    }
    .navbar-brand {
        font-size: 16pt;
        font-weight: 300;
    }
    .navbar-nav .nav-item {
        font-size: 10pt;
        text-transform: uppercase;
        &:hover {
            color: rgba(0, 0, 0, 0.9);
        }
    }
    .dropdown-item {
        font-size: 10pt;
        padding: 4px 12px;
    }
}
section {
    max-width: 1320px;
    padding: 0 2%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    &.fullwidth {
        max-width: 100% !important;
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    &.stretched {
        max-width: unset;
        width: 100%;
        text-align: center;
        background-color: #000000;
        margin: 0;
        position: absolute;
        margin-top: 100px;
        padding: 24px;
        ul {
            list-style: none;
            margin: 9px 0;
            li {
                color: aliceblue;
                font-size: 10pt;
                font-weight: 300;
                text-transform: uppercase;
                display: inline-block;
                padding: 4px 8px;
                cursor: pointer;
            }
        }
        .rights {
            font-size: 8pt;
            font-weight: 200;
            color: azure;
            margin: 10px 0;
        }
        .logo {
            margin-top: 10px;
        }
    }
    >.title {
        //text-transform: uppercase;
        font-size: 30pt;
        font-weight: 300;
        padding: 80px 0 40px;
    }
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.banner {
    height: 380px;
    margin: 20px 0;
    text-align: right;
    max-height: 800px;
    p {
        display: block;
        margin: 40px;
    }
    div {
        width: 30%;
        display: inline-block;
        margin: 0;
        height: 100%;
        vertical-align: top;
        position: relative;
    }
    .pre, .post {
        position: relative;
        display: inline-block;
        color: aliceblue;
        vertical-align: middle;
        padding: 2px 12px;
        margin-top: -14pt;
        top: 50%;
        font-size: 24pt;
        font-weight: 200;
        text-transform: uppercase;
        border-radius: 6px;
        background-color: rgb(48, 48, 48);
    }
    .carosal {
        left: 0;
        height: 100%;
        vertical-align: middle;
        display: inline-block;
        width: 40%;
        float: left;
        text-align: left;
        position: absolute;
        z-index: 1;
        margin-top: 10%;
        span.main {
            font-size: 45pt;
            line-height: 50pt;
            font-weight: 200;
            margin: 10% 0 0 10%;
            display: inline-block;
        }
        ul {
            display: block;
            list-style: none;
            position: relative;
            margin: 10% 0 0 10%;
            &.main {
                li {
                    font-size: 45pt;
                    line-height: 50pt;
                    font-weight: 200;
                   // text-transform: capitalize;
                    padding: 0;
                    margin-bottom: 6px;
                }
            }
            &.bullets {
                margin-top: 2%;
                li {
                    display: inline-block;
                    background-color: #44d4ff;
                    border-radius: 4px;
                    padding: 4px 6px;
                    &:first-child {
                        background-color: #fccb00;
                    }
                    &:last-child {
                        background-color: #ff4c1d;
                    }
                }
            }
        }
    }
}
.description {
    background: linear-gradient(to bottom, #0b3457, #010f09);
    max-width: 100% !important;
    .max > div {
        display: inline-block;
        color: #b2b2b2;
        width: 50%;
        vertical-align: middle;
        font-weight: 200;
        padding: 30px 12px;
    }
    .pre {
        font-size: 18pt;        
        text-align: center;
    }
    .post {
        font-size: 13pt;
        text-align: left;
    }
    .desc {
       // width: 80% !important;
        font-size: 15pt;
    }
}
.services {
    display: flex;
    padding: 30px 0 120px;
    @extend .lightblue;
    >.title {    
        width: 38%;
        display: inline-block;
        font-size: 40pt;
        line-height: 42pt;
        font-weight: 200;
        height: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            display: block;
        }
    }
    ul {
        display: inline-block;
        width: 60%;
        text-align: left;
    }
    .item {
        text-align: center;
        display: inline-block;    
        width: 31%;
        margin: 20px 1%;
        vertical-align: top;
        .img {
            display: inline-block;
            height: 100px;
            width: 100px;
        }
        .title {
            font-size: 14pt;
            font-weight: 300;
        }
        .content {
            font-size: 12pt;
            font-weight: 300;
        }
    }
}
.experience {
    display: block;
    max-width: 80%;
    margin: 30px auto;
    margin-top: -60px;
    @extend .darkblue;
    border-radius: 6px;
    .title {
        color: white;
        font-size: 25pt;
        padding: 20px;
        font-weight: 200;
        word-break: break-word;
    }
    ul {
        padding-bottom: 30px;
        .item {
            display: inline-block;
            min-width: 140px;
            padding: 10px 12px;
            background-color: white;
            border: 1px solid #888;
            border-radius: 8px;
            color: #2e2e2e;
            margin: 8px;
            font-size: 12pt;
        }
    }
}

.skills {
    margin: 40px auto;
    padding: 40px 0;
    .img {
        height: 200px !important;
        width: 200px !important;
        margin: 0;
    }
    span > span {
        cursor: pointer;
        display: inline-block;
        margin: auto;
        vertical-align: middle;
        margin-left: 8px;
        font-size: 25pt;
        font-weight: 300;
        &.bold {
            font-weight: bold;
            margin-right: 50px;
        }
    }
}
#whyus > p {
    padding: 10px 40px;
    font-size: 14pt;
    font-weight: 300;
}
.whyus {
    ul .item {
        vertical-align: top;;
        display: inline-block;
        width: 30%;
        border-radius: 8px 4px;
        color: #444;
        margin: 12px 1%;
        .img {
            vertical-align: top;
            width: 100px;
            display: inline-block;
            margin-top: 4px;
        }
        .desc {
            text-align: left;
            font-size: 14px;
            padding: 4px;
            width: calc(100% - 100px);
            display: inline-block;
            .title {
                text-transform: uppercase;
                font-size: 14pt;
                font-weight: 400;
                margin-bottom: 4px;
            }
            .content {
                word-break: break-word;
                font-size: 12pt;
                font-weight: 300;
            }
        }
    }
}
.contact {
    border: 1px solid #868686;
    display: inline-block;
    border-radius: 8px;
    font-size: 30pt;
    padding: 12px 60px;
    cursor: pointer;
}
#contact {
    .title {
        text-transform: unset;
        word-break: keep-all;
        > p {
            margin: 0;
            text-align: center;
        }
    }
}
.opl-pop {
    display: none;
    .contactform {
        > div {
            vertical-align: top;
        }
        .about {
            display: inline-block;
            // width: 40%;
            vertical-align: top;
            word-break: break-all;
            .about-row {
                padding-bottom: 20px;
            }
            .about-col {
                font-weight: 300;
                text-align: left;
            }
        }
        .address {
            display: inline-block;
            width: 40%;
            vertical-align: top;
            word-break: break-all;
            span {
                font-weight: 300;
                padding: 10px 20px;
                display: block;
            }
        }
        .address-divider {
            border-right: 1px solid #aaa;
        }
        .mailus {
            font-weight: 300;
            padding: 10px 20px;
            display: block;
        }
        .form {
            display: inline-block;
            // width: 40%;
            border-left: 1px dashed #aaa;
        }
    }
    &.active {
        display: block;
        position: fixed;
        z-index: 1100;
        height: 100%;
        width: 100%;
        max-width: 100% !important;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }
    > section {
        >.header {
            display: block;
            font-size: 30pt;
            font-weight: 300;
            //width: calc(72% + 30px);
            text-align: center;
            margin: 0 auto;
            color: #333;
            padding-bottom: 40px;
        }
        background: #ffffff;
        height: auto;
        width: 80%;
        position: relative;
        margin-top: 2.5%;
        padding: 60px 40px;
        border-radius: 4px;
        box-shadow: 0px 2px 18px #707070;
    }
    .closeform {
        position: absolute;
        right: 20px;
        top: 20px;
        display: inline-block;
        width: auto;
        font-weight: 100;
        font-stretch: ultra-expanded;
        font-size: 18pt;
        cursor: pointer;
    }
}
.all-skills {
    ul {
        display: block;
        list-style: none;
        &.tabs {
            li {
                vertical-align: top;;
                display: inline-block;
                border-radius: 4px;
                color: #444;
                margin: 12px 1%;
                padding: 8px 16px;
                cursor: pointer;
                @extend .lightblue;
                &:hover {
                    border: 1ppx solid #dfeef0;
                }
                &.active {
                    background-color: #bee3ff;
                }
            }
        }
        &.images {
            margin-left: 0 10%;
            li {
                display: inline-block;
                width: 150px;
                // border: 1px solid #DDDDDD;
                // border-collapse: collapse;
                // -moz-box-shadow: 0px 0px 0px 1px #DDDDDD;
                // -webkit-box-shadow: 0px 0px 0px 1px #DDDDDD;
                // box-shadow: 0px 0px 0px 1px #DDDDDD;
                .title {
                    font-size: 10pt;
                    font-weight: 300;
                    padding: 0px 0 16px;
                    margin-top: -8px;
                    display: block;
                }
                .skill-img {
                    img {
                        vertical-align: middle;
                        border-style: none;
                        background-size: contain;
                        height: 100%;
                    }
                }
            }
        }
    }
}
/**
* Local Media queries
*/
@media only screen and (max-width: 2000px) {
    .navbar .container, section.max {
        max-width: 1320px;
    }
    .services .item {
        width: 30%;
        margin: 20px 1%;
    }
    .banner {
        .img.banner-img, .skill-img.banner-img {
            height: 80%;
        }
    }
}
@media only screen and (max-width: 1600px) {
    .navbar .container, section.max {
        max-width: 1280px;
    }
    .banner {
        .img.banner-img, .skill-img.banner-img {
            height: 80%;
        }
    }
}
@media only screen and (max-width: 1300px) {
    .navbar .container, section.max {
        max-width: 1140px;
    }
    .banner {
        .img.banner-img {
            height: 65%;
            margin: 5% 0 0 0;
        }
    }
}
@media only screen and (max-width: 1200px) {
    .navbar .container, section.max {
        max-width: 980px;
    }
    .services {
        display: block;
        >.title {
            width: 100%;
            font-size: 32pt;
            margin-bottom: 20px;
        }
        .item {
            width: 28%;
            margin: 20px 2%;
        }
    }
    .whyus ul .item {
        width: 45%;
    }
    .banner div {
        width: 100%;
    }
    .banner {
        height: 520px;
    }
}
label.required:after {
    content:"*";
    color:red;
}
.opl-alert {
    display: none;
    position: absolute;
    z-index: 1111;
    top: 0;
    height: 100%;
    width: 100%;
    /* background-color: #fff; */
    margin: auto;
    margin: 0px 0 0 -40px;
    border-radius: 4px;
    &.active {
        display: block;
    }
    .overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.2);
    }
    .container {
        background-color: #fff;
        position: relative;
        height: auto;
        width: 420px;
        border-radius: 4px;
        margin-top: 12%;
        > h4 {
            font-size: 16pt;
            margin: 0;
            padding: 12px 0 16px;
        }
        > p {
            color: #595959;
        }
        > div {
            padding: 12px 0;
            button {
                padding: 6px 24px;
            }
        }
    }
}
@media only screen and (max-width: 1000px) {
    section.max {
        > .title {
            font-size: 24pt;
            text-align: center;
        }
        .contact {
            font-size: 20pt;
        }

    }
    .navbar .container, section.max {
        max-width: 760px;
    }
    .banner {
        height: 520px;
        .carosal {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
            text-align: center;
            margin-top: 0;
            ul {
                margin-top: 75px;
                margin-left: 0;
                &.main li {
                    font-size: 28pt;
                    line-height: 30pt;
                }
                &.bullets {
                    margin-top: 10px;
                }
            }
            span.main {
                margin-top: 75px;
                font-size: 28pt;
                line-height: 30pt;
            }
        }
        .img.banner-img, .skill-img.banner-img {
            height: 50%;
            background-position: 50% 0;
        }
    }
    .contact {
        font-size: 26pt;
    }
    .opl-pop .contactform {
        width: 80%;
        margin: auto 2%;
        margin-top: 80px;
        // .address {
        //     width: 37%;
        // }
        // .form {
        //     width: 62%;
        // }
    }
}
@media only screen and (max-width: 800px) {
    section.max {
        max-width: 580px;
        > .title {
            padding: 80px 10% 40px;
            text-align: center;
        }
        .contact {
            font-size: 18pt;
        }
    }
    .opl-alert {
        margin-left: -20px;
        .container {
            width: 80%;
            margin-left: 10%;
            margin-top: 36%;
            position: fixed;
        }
    }
    .services {
        ul {
            width: 80%;
        }
        .item {
            width: 40%;
            margin: 20px 5%;
        }
        >.title {
            font-size: 28pt;
        }
    }
    .banner {
        height: 520px;
        .carosal span.main {
            margin: 60px auto;
        }
        .carosal .bullets {
            margin-bottom: 20px;
        }
    }
    .description .max > div {
        width: 100%;        
        padding: 40px 12px;
        &.post {
            padding-top: 20px;
            border-top: 1px dashed #ccc;
            text-align: center;
        }
        &.pre {
            padding-bottom: 20px;
            text-align: center;
        }
    }
    .contact {
        font-size: 24pt;
    }
    .whyus ul .item {
        width: 80%;
    }
    .experience ul .item {
        min-width: unset;
    }
    .opl-pop {
        padding: 0;
        .contactform {
            width: 100%;
            height: 100%;
            margin: 0;
            border-radius: 0;
            padding: 20px;
            overflow: scroll;
            .address, .form {
                width: 100%;
                border: none;
                > p {
                    margin-bottom: 4px;
                }
                > span {
                    padding: 10px 8px 0;
                }
            }
            .address{
                // border-bottom: 1px dashed #aaa;
                padding-bottom: 25px;
            }
            .form{
                margin-top: 25px;
            }
            form .input {
                width: 100%;
                margin: 0;
            }
            form .input.message {
                width: 100% ;
                margin: 0;
            }
        }
    }
}
@media only screen and (max-width: 575px) {
    .about-col-title {
        color: #b0b0b0;
    }
}
