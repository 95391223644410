/* Fonts */
@font-face {
    font-family: "WorkSans";
    src: url("../fonts/WorkSans-Bold.ttf");
    font-weight: 700;
}
@font-face {
    font-family: "WorkSans";
    src: url("../fonts/WorkSans-ExtraBold.ttf");
    font-weight: 1000;
}
@font-face {
    font-family: "WorkSans";
    src: url("../fonts/WorkSans-Light.ttf");
    font-weight: 200;
}
@font-face {
    font-family: "WorkSans";
    src: url("../fonts/WorkSans-Medium.ttf");
    font-weight: 400;
}
@font-face {
    font-family: "WorkSans";
    src: url("../fonts/WorkSans-Regular.ttf");
    font-weight: 300;
}
@font-face {
    font-family: "WorkSans";
    src: url("../fonts/WorkSans-SemiBold.ttf");
    font-weight: 600;
}
@font-face {
    font-family: "WorkSans";
    src: url("../fonts/WorkSans-Thin.ttf");
    font-weight: 100;
}
/* Fonts */
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Bold.ttf");
    font-weight: 700;
}
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Black.ttf");
    font-weight: 1000;
}
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Light.ttf");
    font-weight: 200;
}
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Medium.ttf");
    font-weight: 500;
}
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular.ttf");
    font-weight: 300;
}
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-thin.ttf");
    font-weight: 100;
}
